import React from "react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/core";
import theme from "../../theme";
import { useAppApolloClient } from "../hooks/useAppApolloClient";
import { AuthGate } from "./AuthGate";

const Providers = () => {
    const client = useAppApolloClient();

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <AuthGate />
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default Providers;
