import { makeStyles, SvgIconTypeMap, Typography } from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import React from "react"

const useStyles = makeStyles((theme) => ({
    title: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    icon: {
        marginRight: theme.spacing(0.5)
    }
}))
interface ITitleProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    children: string
}

const Title = ({ icon, children: text }: ITitleProps) => {
    const classes = useStyles()

    return (
        <Typography variant="h5" className={classes.title}>
            {React.createElement(icon, {
                className: classes.icon
            })}
            {text}
        </Typography>
    )
}

export default Title
