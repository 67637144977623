import React, { useState } from "react";

interface IAuthContext {
    accessToken: string | null;
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = React.createContext<IAuthContext>({
    accessToken: null,
    setAccessToken: () => {},
});

interface IAuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
    const [token, setToken] = useState<string | null>(null);

    return (
        <AuthContext.Provider
            value={{
                accessToken: token,
                setAccessToken: setToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
