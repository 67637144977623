import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    makeStyles,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(10),
    },
}));

interface IErrorAlertProps {
    show: boolean;
    message: string;
    onClose: () => void;
}

const ErrorAlert = ({ show, message, onClose }: IErrorAlertProps) => {
    const classes = useStyles();

    return (
        <Dialog open={show} onClose={onClose} className={classes.dialog}>
            <Box p={4} flex="1" width="20vw">
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="row"
                    justifyItems="center"
                    alignItems="center"
                    alignContent="center"
                    marginBottom="1rem"
                >
                    <ErrorIcon color="error" fontSize="large" />
                    <Typography variant="h6" color="error">
                        ERROR
                    </Typography>
                </Box>
                <Typography color="error">{message}</Typography>
                <DialogActions>
                    <Button onClick={onClose} color="default">
                        Close
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ErrorAlert;
