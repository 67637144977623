import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Snackbar,
} from "@material-ui/core";
import React, { useState } from "react";
import ErrorAlert from "../../../../../common/components/ErrorAlert";
import {
    BuySellEntityType,
    GetBalancesDocument,
    GetBuyQueueDocument,
    GetSellQueueDocument,
    GetSharesDocument,
    GetTradesDocument,
    useSellMutation,
} from "../../../../../generated/graphql";
import SellForm from "./SellForm";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface Props {}

const Sell = (props: Props) => {
    const classes = useStyles();

    const [sell, { loading }] = useSellMutation();

    const [shareID, setShareID] = useState<string | null>();
    const [price, setPrice] = useState<string | null>();
    const [shares, setShares] = useState<string>("1");
    const [entityID, setEntityID] = useState<string>("");
    const [entityType, setEntityType] = useState<BuySellEntityType>(
        BuySellEntityType.Player
    );

    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSell = async () => {
        try {
            const { data } = await sell({
                variables: {
                    input: {
                        shareID,
                        price: price ? Number(price) : undefined,
                        shares: Number(shares),
                        entityID,
                        entityType,
                    },
                },
                refetchQueries: [
                    {
                        query: GetSharesDocument,
                        variables: {
                            limit: 100,
                            page: 0,
                        },
                    },
                    {
                        query: GetTradesDocument,
                        variables: {
                            limit: 100,
                            page: 0,
                        },
                    },
                    {
                        query: GetSellQueueDocument,
                    },
                    {
                        query: GetBuyQueueDocument,
                    },
                    { query: GetBalancesDocument },
                ],
            });

            if (data?.sell.success) {
                // Refetch shares
                setErrorMessage(null);
                return setShowSuccessMessage(true);
            }
        } catch (err) {
            console.log(err);
            setErrorMessage(err.message);
        }
    };

    const clearForm = () => {
        setShareID(undefined);
        setPrice(undefined);
        setShares("1");
        setEntityID("");
        setEntityType(BuySellEntityType.Player);
    };

    return (
        <>
            {loading && (
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <ErrorAlert
                message={errorMessage ?? ""}
                show={!!errorMessage}
                onClose={() => setErrorMessage(null)}
            />
            <SellForm
                shareID={shareID}
                setShareID={setShareID}
                price={price}
                setPrice={setPrice}
                shares={shares}
                setShares={setShares}
                entityID={entityID}
                setEntityID={setEntityID}
                entityType={entityType}
                setEntityType={setEntityType}
                clearForm={clearForm}
                onSubmit={handleSell}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={showSuccessMessage}
                autoHideDuration={3000}
                onClose={() => setShowSuccessMessage(false)}
                message={`${shares} Shares sold`}
            />
        </>
    );
};

export default Sell;
