import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import LoginForm from "./login.form";
import { useLogin } from "../../common/hooks/useLogin";

// interface ILoginPageProps {
//     login: (email: string, password: string) => Promise<string | boolean>;
// }

const LoginPage = () => {
    const login = useLogin();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async (email: string, password: string) => {
        setLoading(true);
        const result = await login(email, password);

        if (result !== true) {
            if (typeof result === "string") setError(result);
            else setError("Email or password incorrect");
        }
        setLoading(false);
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
        >
            <LoginForm onSubmit={handleLogin} loading={loading} />
            {error && error.length > 0 && (
                <Typography variant="caption" color="error">
                    {error}
                </Typography>
            )}
        </Grid>
    );
};

export default LoginPage;
