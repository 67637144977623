import React from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core"
import BuySellIcon from "@material-ui/icons/LocalAtm"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Buy from "./buy/Buy"
import Sell from "./sell/Sell"
import Title from "../../../../common/components/Title"

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    container: {
        marginTop: theme.spacing(4)
    }
}))

interface Props {}

const SideBar = (props: Props) => {
    const classes = useStyles()

    return (
        <Paper className={classes.paper}>
            <Title icon={BuySellIcon}>Buy/Sell</Title>

            <div className={classes.container}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Buy</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Buy />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Sell</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Sell />
                    </AccordionDetails>
                </Accordion>
            </div>
        </Paper>
    )
}

export default SideBar
