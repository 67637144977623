import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Snackbar,
} from "@material-ui/core";
import React, { useState } from "react";
import ErrorAlert from "../../../../../common/components/ErrorAlert";
import {
    BuySellEntityType,
    GetBalancesDocument,
    GetBuyQueueDocument,
    GetSellQueueDocument,
    GetSharesDocument,
    GetTradesDocument,
    useBuyMutation,
} from "../../../../../generated/graphql";
import BuyForm from "./BuyForm";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface Props {}

const Buy = (props: Props) => {
    const classes = useStyles();

    const [buy, { loading }] = useBuyMutation();

    const [stopLoss, setStopLoss] = useState<string | null>();
    const [takeProfit, setTakeProfit] = useState<string | null>();
    const [price, setPrice] = useState<string | null>();
    const [shares, setShares] = useState<string>("1");
    const [entityID, setEntityID] = useState<string>("");
    const [entityType, setEntityType] = useState<BuySellEntityType>(
        BuySellEntityType.Player
    );

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleBuy = async () => {
        try {
            const { data } = await buy({
                variables: {
                    input: {
                        price: price ? Number(price) : undefined,
                        stopLoss: stopLoss ? Number(stopLoss) : undefined,
                        takeProfit: takeProfit ? Number(takeProfit) : undefined,
                        shares: Number(shares),
                        entityID,
                        entityType,
                    },
                },
                refetchQueries: [
                    {
                        query: GetSharesDocument,
                        variables: {
                            limit: 100,
                            page: 0,
                        },
                    },
                    {
                        query: GetTradesDocument,
                        variables: {
                            limit: 100,
                            page: 0,
                        },
                    },
                    {
                        query: GetBuyQueueDocument,
                    },
                    {
                        query: GetSellQueueDocument,
                    },
                    { query: GetBalancesDocument },
                ],
            });

            if (data?.buy.success) {
                // Refetch shares
                setErrorMessage(null);
                return setSuccessMessage(
                    `${data.buy.matched ?? 0} shares matched, ${
                        data.buy.marketMaker ?? 0
                    } from market maker`
                );
            }
        } catch (err) {
            console.log(err);
            setErrorMessage(err.message);
        }
    };

    const clearForm = () => {
        setPrice(undefined);
        setShares("1");
        setEntityID("");
        setEntityType(BuySellEntityType.Player);
    };

    return (
        <>
            {loading && (
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <ErrorAlert
                message={errorMessage ?? ""}
                show={!!errorMessage}
                onClose={() => setErrorMessage(null)}
            />
            <BuyForm
                price={price}
                setPrice={setPrice}
                stopLoss={stopLoss}
                setStopLoss={setStopLoss}
                takeProfit={takeProfit}
                setTakeProfit={setTakeProfit}
                shares={shares}
                setShares={setShares}
                entityID={entityID}
                setEntityID={setEntityID}
                entityType={entityType}
                setEntityType={setEntityType}
                clearForm={clearForm}
                onSubmit={handleBuy}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!successMessage}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage(null)}
                message={`${shares} Shares bought`}
            />
        </>
    );
};

export default Buy;
