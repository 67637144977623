import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import { BuySellEntityType } from "../../../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > *": {
            margin: theme.spacing(1),
            width: "75%",
        },
    },
    sellButton: {
        marginRight: theme.spacing(1),
    },
}));

interface SellFormProps {
    shareID: string | undefined | null;
    setShareID: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    price: string | undefined | null;
    setPrice: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    shares: string;
    setShares: React.Dispatch<React.SetStateAction<string>>;
    entityID: string;
    setEntityID: React.Dispatch<React.SetStateAction<string>>;
    entityType: BuySellEntityType;
    setEntityType: React.Dispatch<React.SetStateAction<BuySellEntityType>>;
    clearForm: () => void;
    onSubmit: () => Promise<void>;
}

const SellForm = ({
    shareID,
    setShareID,
    price,
    setPrice,
    shares,
    setShares,
    entityID,
    setEntityID,
    entityType,
    setEntityType,
    clearForm,
    onSubmit,
}: SellFormProps) => {
    const classes = useStyles();

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <List className={classes.root}>
                <TextField
                    variant="filled"
                    label="Share ID (Optional)"
                    helperText="If no share is sold, you will short the market"
                    value={shareID}
                    onChange={(e) => setShareID(e.target.value)}
                />
                <TextField
                    variant="filled"
                    label="Price (Optional)"
                    helperText="Leave empty to instant buy"
                    // type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <TextField
                    variant="filled"
                    label="Shares"
                    helperText="Number of shares to buy"
                    // type="number"
                    required={!shareID || shareID.trim().length === 0}
                    disabled={!!shareID && shareID.trim().length > 0}
                    value={shares}
                    onChange={(e) => setShares(e.target.value)}
                />
                <TextField
                    variant="filled"
                    label="Player/Manager ID"
                    helperText="IDs can be found in the admin panel player/manager list"
                    required
                    value={entityID}
                    onChange={(e) => setEntityID(e.target.value)}
                />
                <FormControl component="fieldset">
                    <FormLabel component="legend">Entity Type</FormLabel>
                    <RadioGroup
                        aria-label="entityType"
                        name="entityType1"
                        value={entityType}
                        onChange={(e) =>
                            setEntityType(e.target.value as BuySellEntityType)
                        }
                    >
                        <FormControlLabel
                            value={BuySellEntityType.Player}
                            control={<Radio />}
                            label="Player"
                        />
                        <FormControlLabel
                            value={BuySellEntityType.Manager}
                            control={<Radio />}
                            label="Manager"
                        />
                    </RadioGroup>
                </FormControl>
                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.sellButton}
                        onClick={onSubmit}
                    >
                        Sell
                    </Button>
                    <Button variant="outlined" onClick={clearForm}>
                        Clear
                    </Button>
                </Box>
            </List>
        </form>
    );
};

export default SellForm;
