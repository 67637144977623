import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#4dabf5",
            main: "#2196f3",
            dark: "#1769aa",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff4569",
            main: "#ff1744",
            dark: "#b2102f",
            contrastText: "#fff",
        },
    },
});

export default theme;
