import { Box } from "@material-ui/core";
import React from "react";

interface ITabPanelProps {
    selectedIndex: number;
    index: number;
    children: React.ReactNode;
}
const TabPanel = ({
    index,
    selectedIndex,
    children,
    ...props
}: ITabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={selectedIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {selectedIndex === index && <Box padding="1rem 0">{children}</Box>}
        </div>
    );
};

export default TabPanel;
