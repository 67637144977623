import React from "react";
import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetSharesQuery } from "../../../../generated/graphql";
import Share from "./Share";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
}));

interface Props {}

const SharesTable = (props: Props) => {
    const classes = useStyles();

    const { data: shares, loading } = useGetSharesQuery({
        variables: {
            limit: 100,
            page: 0,
        },
    });

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableCell className={classes.long}>ID</TableCell>
                    <TableCell className={classes.short} align="right">
                        Amount
                    </TableCell>
                    <TableCell className={classes.short} align="right">
                        Price
                    </TableCell>
                    <TableCell className={classes.short} align="right">
                        Stop Loss
                    </TableCell>
                    <TableCell className={classes.short} align="right">
                        Take Profit
                    </TableCell>
                    <TableCell className={classes.long}>
                        Player/Manager
                    </TableCell>
                    {/* <TableCell>Trade ID</TableCell> */}
                    <TableCell className={classes.long}>Bought</TableCell>
                </TableHead>
            </Table>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <TableBody>
                    {shares?.me.shares.edges?.map((share) => {
                        const entity = share.node.player ?? share.node.manager;

                        return (
                            <Share
                                id={share.node.id}
                                key={share.node.id}
                                amount={share.node.amount}
                                price={share.node.price}
                                stopLoss={share.node.stopLoss}
                                takeProfit={share.node.takeProfit}
                                entityID={entity ? entity.id : ""}
                                entityName={
                                    entity
                                        ? `${entity.firstName} ${entity.lastName}`
                                        : ""
                                }
                                entityPrice={entity?.buyPrice ?? 0}
                                created={new Date(share.node.created)}
                            />
                        );
                    })}
                </TableBody>
            )}
        </TableContainer>
    );
};

export default SharesTable;
