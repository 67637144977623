import React from "react";
import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetTradesQuery } from "../../../../generated/graphql";
import Trade from "./Trade";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
}));

interface Props {}

const TradesTable = (props: Props) => {
    const classes = useStyles();

    const { data: trades, loading } = useGetTradesQuery({
        variables: {
            limit: 100,
            page: 0,
        },
    });

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableCell className={classes.long}>ID</TableCell>
                    <TableCell className={classes.short} align="right">
                        Shares
                    </TableCell>
                    <TableCell className={classes.short} align="right">
                        Price
                    </TableCell>
                    <TableCell className={classes.long}>
                        Player/Manager
                    </TableCell>
                    <TableCell className={classes.short}>Type</TableCell>
                    <TableCell className={classes.short}>Method</TableCell>
                    <TableCell className={classes.short}>Status</TableCell>
                    {/* <TableCell>Trade ID</TableCell> */}
                    <TableCell className={classes.long}>Opened</TableCell>
                </TableHead>
            </Table>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <TableBody>
                    {trades?.me.trades.edges?.map((trade) => {
                        const entity = trade.node.player ?? trade.node.manager;
                        return (
                            <Trade
                                id={trade.node.id}
                                key={trade.node.id}
                                shares={trade.node.shares}
                                price={trade.node.price}
                                entityID={entity ? entity.id : ""}
                                entityName={
                                    entity
                                        ? `${entity.firstName} ${entity.lastName}`
                                        : ""
                                }
                                entityPrice={entity?.buyPrice ?? 0}
                                type={trade.node.type}
                                method={trade.node.method}
                                status={trade.node.status}
                                created={new Date(trade.node.created)}
                            />
                        );
                    })}
                </TableBody>
            )}
        </TableContainer>
    );
};

export default TradesTable;
