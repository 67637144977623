import { useContext, useEffect } from "react";
import { AuthContext } from "../providers/auth.provider";

/**
 * Get, set and remove the access token from local storage
 * @returns access token, function to set access token, function to remove access token
 */
export const useAccessToken = (): [
    string | null,
    (accessToken: string) => void,
    () => void
] => {
    const { accessToken: token, setAccessToken: setToken } = useContext(
        AuthContext
    );
    const localStorageName = "FE-OB-DEMO.accessToken";

    useEffect(() => {
        setToken(window.localStorage.getItem(localStorageName));
    }, [setToken]);

    const setAccessToken = (accessToken: string) => {
        setToken(accessToken);
        window.localStorage.setItem(localStorageName, accessToken);
    };

    const removeAccessToken = () => {
        setToken(null);
        window.localStorage.removeItem(localStorageName);
    };

    return [token, setAccessToken, removeAccessToken];
};
