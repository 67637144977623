import { Chip, makeStyles, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { OrderStatus } from "../../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
}));

interface ISellOrderProps {
    id: string;
    price: number;
    totalShares: number;
    remainingShares: number;
    status: OrderStatus;
    tradeID: string;
    created: Date;
}

const SellOrder = ({
    id,
    price,
    totalShares,
    remainingShares,
    status,
    tradeID,
    created,
}: ISellOrderProps) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.long}>{id}</TableCell>
            <TableCell className={classes.short} align="right">
                {price}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {totalShares}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {remainingShares}
            </TableCell>
            <TableCell className={classes.short}>
                <Chip
                    label={status}
                    color={status === OrderStatus.Open ? "primary" : "default"}
                />
            </TableCell>
            <TableCell className={classes.long}>{tradeID}</TableCell>
            <TableCell className={classes.long}>
                {created.toUTCString()}
            </TableCell>
        </TableRow>
    );
};

export default SellOrder;
