import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

/** Create a new user */
export type AdminUserCreateInput = {
  /** User's address */
  address: PostalAddressInput;
  /** User's date of birth */
  dob: Scalars['DateTime'];
  /** User's email. Must be in valid email format. */
  email: Scalars['String'];
  /** User's first name. 1-100 characters. */
  firstName: Scalars['String'];
  /** User's last name. 1-100 characters. */
  lastName: Scalars['String'];
  /** User's marketing preferences. */
  marketing?: Maybe<MarketingPreferencesInput>;
  /** User's password. Min 8 characters */
  password: Scalars['String'];
  /** User's email. Must be in valid email format. */
  phone: Scalars['String'];
  /** User access role */
  role: Scalars['String'];
  /** User's terms of service agreement. */
  tos: Scalars['Boolean'];
};

export type AdminUserUpdateInput = {
  /** User's address */
  address?: Maybe<PostalAddressInput>;
  /** User's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** User's email. Must be in valid email format. */
  email?: Maybe<Scalars['String']>;
  /** User's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  /** Is the user's account locked */
  locked?: Maybe<Scalars['Boolean']>;
  /** User's marketing preferences. */
  marketing?: Maybe<MarketingPreferencesInput>;
  /** User's password. Min 8 characters */
  password?: Maybe<Scalars['String']>;
  /** User's email. Must be in valid email format. */
  phone?: Maybe<Scalars['String']>;
  /** User access role */
  role?: Maybe<Scalars['String']>;
  /** User's account timeout */
  timeout?: Maybe<Scalars['DateTime']>;
  /** User's terms of service agreement. */
  tos?: Maybe<Scalars['Boolean']>;
};

export type AnalystRating = {
  __typename?: 'AnalystRating';
  /** Percentage selling */
  buy: Scalars['Float'];
  /** Percentage holding */
  hold: Scalars['Float'];
  /** Percentage selling */
  sell: Scalars['Float'];
};

/** Article model */
export type Article = {
  __typename?: 'Article';
  /** Article body text */
  body: Scalars['String'];
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** URL of article's header image */
  image?: Maybe<Scalars['String']>;
  /** Article's associated managers */
  managers?: Maybe<Array<Manager>>;
  /** Article's associated players */
  players?: Maybe<Array<Player>>;
  /** Article title */
  publisher: Scalars['String'];
  /** Short article description */
  teaser: Scalars['String'];
  /** Article title */
  title: Scalars['String'];
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
};

/** Paginated list of articles */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  edges?: Maybe<Array<ArticleEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new news article */
export type ArticleCreateInput = {
  /** Article body. Main article content. HTML or plaintext. Must not be empty */
  body: Scalars['String'];
  /** URL to JPG or PNG image for the article header. Must be a URL. */
  image: Scalars['String'];
  /** Article's. Must not be empty. */
  publisher: Scalars['String'];
  /** Article subtext. Short summary of the article. Must not be empty */
  teaser: Scalars['String'];
  /** Article's. Must not be empty. */
  title: Scalars['String'];
};

export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  cursor: Scalars['String'];
  node: Article;
};

export type ArticleUpdateInput = {
  /** Article body. Main article content. HTML or plaintext. Must not be empty */
  body?: Maybe<Scalars['String']>;
  /** URL to JPG or PNG image for the article header. Must be a URL. */
  image?: Maybe<Scalars['String']>;
  /** Article's. Must not be empty. */
  publisher?: Maybe<Scalars['String']>;
  /** Article subtext. Short summary of the article. Must not be empty */
  teaser?: Maybe<Scalars['String']>;
  /** Article's. Must not be empty. */
  title?: Maybe<Scalars['String']>;
};

/** Buy order */
export type Buy = {
  __typename?: 'Buy';
  /** Order open date */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Buy at price */
  price: Scalars['Float'];
  /** Shares left to be bought */
  remainingShares: Scalars['Int'];
  /** Order status */
  status: OrderStatus;
  /** Automatically sells off shares if price falls to stop loss value */
  stopLoss?: Maybe<Scalars['Float']>;
  /** Automatically sells off shares if price rises to take profit value */
  takeProfit?: Maybe<Scalars['Float']>;
  /** Total shares at open */
  totalShares: Scalars['Int'];
  /** ID of associated trade */
  tradeID: Scalars['String'];
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
};

export type BuyInput = {
  /** ID of the player or manager */
  entityID: Scalars['String'];
  /** The type of entity to buy or sell shares in */
  entityType: BuySellEntityType;
  /** Buy limit price (if not given, the shares will be instant bought at the current price) */
  price?: Maybe<Scalars['Float']>;
  /** Number of shares to buy */
  shares: Scalars['Float'];
  /** Automatically sell off shares if price falls to stop loss */
  stopLoss?: Maybe<Scalars['Float']>;
  /** Automatically sell off shares if price hits take profit */
  takeProfit?: Maybe<Scalars['Float']>;
};

export type BuyResponse = {
  __typename?: 'BuyResponse';
  /** Number of shares bought by the market maker */
  marketMaker?: Maybe<Scalars['Float']>;
  /** Number of shares matched with sell queue */
  matched?: Maybe<Scalars['Float']>;
  /** Was the purchase successfull? */
  success: Scalars['Boolean'];
  /** Instant or limit */
  type: Scalars['String'];
};

/** Available entity types */
export enum BuySellEntityType {
  Manager = 'MANAGER',
  Player = 'PLAYER'
}

export type Candle = {
  __typename?: 'Candle';
  /** Average price */
  average?: Maybe<Scalars['Float']>;
  /** Closing price */
  close?: Maybe<Scalars['Float']>;
  /** Start date */
  date: Scalars['DateTime'];
  /** Opening Price */
  open?: Maybe<Scalars['Float']>;
};

/** Generate a new candle for a given entity */
export type CandleGenerateInput = {
  /** Amount of days to represent for candles */
  days?: Maybe<Scalars['Float']>;
  /** Candle's duration/period (in minutes) */
  period?: Maybe<Scalars['Float']>;
  /** Starting date for candles */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type Candles = {
  __typename?: 'Candles';
  /** List of candles creating a graph */
  graph: Array<Candle>;
};

/** Club model */
export type Club = {
  __typename?: 'Club';
  /** Club's country */
  country: Scalars['String'];
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Club's managers */
  managers: Array<Manager>;
  /** Club's name */
  name: Scalars['String'];
  /** Club's players */
  players: Array<Player>;
  /** Club's short code */
  shortCode: Scalars['String'];
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** Club's venue */
  venue: Scalars['String'];
};

/** Paginated list of clubs */
export type ClubConnection = {
  __typename?: 'ClubConnection';
  edges?: Maybe<Array<ClubEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new club */
export type ClubCreateInput = {
  /** Club's country */
  country: Scalars['String'];
  /** Club's name. 1-100 characters. */
  name: Scalars['String'];
  /** Club's shortcode. 1-10 characters. */
  shortCode: Scalars['String'];
  /** Club's venue */
  venue?: Maybe<Scalars['String']>;
};

export type ClubEdge = {
  __typename?: 'ClubEdge';
  cursor: Scalars['String'];
  node: Club;
};

export type ClubUpdateInput = {
  /** Club's country */
  country?: Maybe<Scalars['String']>;
  /** Club's name. 1-100 characters. */
  name?: Maybe<Scalars['String']>;
  /** Club's shortcode. 1-10 characters. */
  shortCode?: Maybe<Scalars['String']>;
  /** Club's venue */
  venue?: Maybe<Scalars['String']>;
};

/** Field and value pair. Field must be a property on the entity. */
export type ConnectionFieldFilter = {
  /** Field name */
  field: Scalars['String'];
  /** Field value to match */
  value: Array<Scalars['String']>;
};

/** Filter a list by a query */
export type ConnectionFilter = {
  /** Collection of fields to filter by */
  fields?: Maybe<Array<ConnectionFieldFilter>>;
  /** Simple text search */
  query?: Maybe<Scalars['String']>;
};

/** Sort a list by a field */
export type ConnectionSort = {
  /** Field to sort by */
  field: Scalars['String'];
  /** Field sort order */
  order: ConnectionSortOrder;
};

/** Field sort order */
export enum ConnectionSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}


export type DisabledStats = {
  __typename?: 'DisabledStats';
  /** Is player's appearance count disabled */
  appearances: Scalars['Boolean'];
  /** Is the player's goal count disabled */
  goals: Scalars['Boolean'];
  /** Is player's minutes played count disabled */
  minutesPlayed: Scalars['Boolean'];
  /** Is player's penalty goal count disabled */
  penaltyGoals: Scalars['Boolean'];
  /** Is player's red card count disabled */
  redCards: Scalars['Boolean'];
  /** Is player's yellow card count disabled */
  yellowCards: Scalars['Boolean'];
};

/** Player's disabled stats */
export type DisabledStatsInput = {
  /** Is player's appearance count disabled */
  appearances?: Maybe<Scalars['Boolean']>;
  /** Is the player's goal count disabled */
  goals?: Maybe<Scalars['Boolean']>;
  /** Is player's minutes played count disabled */
  minutesPlayed?: Maybe<Scalars['Boolean']>;
  /** Is player's penalty goal count disabled */
  penaltyGoals?: Maybe<Scalars['Boolean']>;
  /** Is player's red card count disabled */
  redCards?: Maybe<Scalars['Boolean']>;
  /** Is player's yellow card count disabled */
  yellowCards?: Maybe<Scalars['Boolean']>;
};

export type EntityPriceChange = {
  __typename?: 'EntityPriceChange';
  /** Entities change in price */
  change: Scalars['Float'];
  /** Entities change in price as a percentage */
  percentage: Scalars['Float'];
};

/** Manager model */
export type Manager = {
  __typename?: 'Manager';
  analystRating: AnalystRating;
  /** Manager's associated articles */
  articles: Array<Article>;
  /** Manager's current buy price */
  buyPrice: Scalars['Float'];
  /** Manager's club */
  club: Club;
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Manager's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** Manager's first name */
  firstName: Scalars['String'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Manager's image (URL) */
  image?: Maybe<Scalars['String']>;
  isOnWatchlist: Scalars['Boolean'];
  /** Manager's last name */
  lastName: Scalars['String'];
  marketStats: MarketStats;
  name: Scalars['String'];
  /** Manager's nationality */
  nationality: Scalars['String'];
  /** Manager's current price (not public facing) */
  price: Scalars['Float'];
  priceChange: EntityPriceChange;
  priceHistory: Candles;
  /** Manager's current sell price */
  sellPrice: Scalars['Float'];
  /** All open shares owned by the current user in this manager */
  shares?: Maybe<ShareConnection>;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User's watching manager */
  usersWatching: Array<User>;
  /** Manager's visibility */
  visible: Scalars['Boolean'];
};


/** Manager model */
export type ManagerPriceChangeArgs = {
  hours: Scalars['Float'];
};


/** Manager model */
export type ManagerPriceHistoryArgs = {
  input: CandleGenerateInput;
};

/** Paginated list of managers */
export type ManagerConnection = {
  __typename?: 'ManagerConnection';
  edges?: Maybe<Array<ManagerEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new manager */
export type ManagerCreateInput = {
  /** Manager's club ID. */
  clubId: Scalars['String'];
  /** Manager's date of birth */
  dob: Scalars['DateTime'];
  /** Manager's first name. 1-100 characters. */
  firstName: Scalars['String'];
  /** Manager's image */
  image: Scalars['String'];
  /** Manager's last name. 1-100 characters. */
  lastName: Scalars['String'];
  /** Manager's nationality */
  nationality: Scalars['String'];
  /** Managers's price */
  price: Scalars['Float'];
  /** Manager's visibility */
  visible: Scalars['Boolean'];
};

export type ManagerEdge = {
  __typename?: 'ManagerEdge';
  cursor: Scalars['String'];
  node: Manager;
};

export type ManagerUpdateInput = {
  /** Manager's club ID. */
  clubId?: Maybe<Scalars['String']>;
  /** Manager's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** Manager's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  /** Manager's image */
  image?: Maybe<Scalars['String']>;
  /** Manager's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  /** Manager's nationality */
  nationality?: Maybe<Scalars['String']>;
  /** Managers's price */
  price?: Maybe<Scalars['Float']>;
  /** Manager's visibility */
  visible?: Maybe<Scalars['Boolean']>;
};

/** User's marketing preferences */
export type MarketingPreferences = {
  __typename?: 'MarketingPreferences';
  /** If the user has opted into marketing updates regarding general news */
  news: Scalars['Boolean'];
  /** If the user has opted into marketing updates regarding new offers */
  offers: Scalars['Boolean'];
  /** If the user has opted into marketing updates regarding new updates */
  updates: Scalars['Boolean'];
};

/** User's marketing preferences */
export type MarketingPreferencesInput = {
  /** If the user has opted into marketing updates regarding general news */
  news?: Maybe<Scalars['Boolean']>;
  /** If the user has opted into marketing updates regarding new offers */
  offers?: Maybe<Scalars['Boolean']>;
  /** If the user has opted into marketing updates regarding new updates */
  updates?: Maybe<Scalars['Boolean']>;
};

/** Market Stats */
export type MarketStats = {
  __typename?: 'MarketStats';
  /** Market cap */
  cap: Scalars['Float'];
  /** Market high (24h) */
  high: Scalars['Float'];
  /** Market low (24h) */
  low: Scalars['Float'];
  /** Market opening */
  open: Scalars['Float'];
  /** Market volume (24h) */
  volume: Scalars['Float'];
  /** Market volume average (7d) */
  volumeAverage: Scalars['Float'];
  /** Market high (52w) */
  yearHigh: Scalars['Float'];
  /** Market high (52w) */
  yearLow: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Admin function to create a new user */
  adminUserCreate?: Maybe<UserCreateResponse>;
  /** Admin function to delete a user */
  adminUserDelete?: Maybe<User>;
  /** Admin function to remove a user's account timeout */
  adminUserRemoveTimeout?: Maybe<User>;
  /** Delete a set of users */
  adminUsersDelete?: Maybe<Array<Scalars['String']>>;
  /** Admin function to set a user's account timeout */
  adminUserSetTimeout?: Maybe<User>;
  /** Admin function to update a user */
  adminUserUpdate?: Maybe<User>;
  /** Create a new article object */
  articleCreate?: Maybe<Article>;
  /** Delete existing article */
  articleDelete?: Maybe<Article>;
  /** Delete a set of articles */
  articlesDelete: Array<Scalars['ID']>;
  /** Update an existing article object */
  articleUpdate?: Maybe<Article>;
  buy: BuyResponse;
  /** Create a new club object */
  clubCreate?: Maybe<Club>;
  /** Delete existing club */
  clubDelete?: Maybe<Club>;
  /** Delete a set of clubs */
  clubsDelete: Array<Scalars['ID']>;
  /** Update an existing club object */
  clubUpdate?: Maybe<Club>;
  /** Get an access token using the user's email and password */
  login?: Maybe<Tokens>;
  /** Revoke a refresh token */
  logout?: Maybe<Scalars['String']>;
  /** Create a manager */
  managerCreate?: Maybe<Manager>;
  /** Delete existing manager */
  managerDelete?: Maybe<Manager>;
  /** Delete a set of managers */
  managersDelete: Array<Scalars['ID']>;
  /** Update's a selection of manager's visibilities */
  managersUpdateVisibility?: Maybe<Scalars['Boolean']>;
  /** Update a manager */
  managerUpdate?: Maybe<Manager>;
  /** Create a new player object */
  playerCreate?: Maybe<Player>;
  /** Delete existing player */
  playerDelete?: Maybe<Player>;
  /** Delete a set of players */
  playersDelete: Array<Scalars['ID']>;
  /** Update's a selection of player's visibilities */
  playersUpdateVisibility?: Maybe<Scalars['Boolean']>;
  /** Update a player */
  playerUpdate?: Maybe<Player>;
  /** Create a new promotion object */
  promotionCreate?: Maybe<Promotion>;
  /** Delete existing promotion */
  promotionDelete?: Maybe<Promotion>;
  /** Delete a set of promotions */
  promotionsDelete: Array<Scalars['ID']>;
  /** Update an existing promotion object */
  promotionUpdate?: Maybe<Promotion>;
  /** Get a new access token from a valid refresh token */
  refresh?: Maybe<Tokens>;
  sell: SellResponse;
  short: ShortResponse;
  /** Activates a user account with a token */
  userActivateAccount?: Maybe<User>;
  /** Sign up for an account */
  userCreate?: Maybe<UserCreateResponse>;
  /** Delete a user */
  userDelete?: Maybe<User>;
  /** Request a password reset for an account */
  userRequestPasswordReset?: Maybe<Scalars['Boolean']>;
  /** Reset a password with a token */
  userResetPassword?: Maybe<User>;
  /** Delete a set of users */
  usersDelete: Array<Scalars['ID']>;
  /** Set a self inflicted account timeout */
  userSetTimeout?: Maybe<User>;
  /** Update an existing customer's details */
  userUpdate?: Maybe<User>;
  /** Add a manager to a user's manager watchlist */
  userWatchlistAddManager?: Maybe<User>;
  /** Add a player to a user's manager watchlist */
  userWatchlistAddPlayer?: Maybe<User>;
  /** Remove a player from a user's watchlist */
  userWatchlistRemoveManager?: Maybe<User>;
  /** Remove a player from a user's player watchlist */
  userWatchlistRemovePlayer?: Maybe<User>;
};


export type MutationAdminUserCreateArgs = {
  input: AdminUserCreateInput;
};


export type MutationAdminUserDeleteArgs = {
  id: Scalars['String'];
};


export type MutationAdminUserRemoveTimeoutArgs = {
  id: Scalars['String'];
};


export type MutationAdminUsersDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationAdminUserSetTimeoutArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
};


export type MutationAdminUserUpdateArgs = {
  id: Scalars['String'];
  input: AdminUserUpdateInput;
};


export type MutationArticleCreateArgs = {
  input: ArticleCreateInput;
};


export type MutationArticleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationArticlesDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationArticleUpdateArgs = {
  id: Scalars['ID'];
  input: ArticleUpdateInput;
};


export type MutationBuyArgs = {
  input: BuyInput;
};


export type MutationClubCreateArgs = {
  input: ClubCreateInput;
};


export type MutationClubDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationClubsDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationClubUpdateArgs = {
  id: Scalars['ID'];
  input: ClubUpdateInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLogoutArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationManagerCreateArgs = {
  input: ManagerCreateInput;
};


export type MutationManagerDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationManagersDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationManagersUpdateVisibilityArgs = {
  ids: Array<Scalars['ID']>;
  visible?: Maybe<Scalars['Boolean']>;
};


export type MutationManagerUpdateArgs = {
  id: Scalars['String'];
  input: ManagerUpdateInput;
};


export type MutationPlayerCreateArgs = {
  input: PlayerCreateInput;
};


export type MutationPlayerDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPlayersDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationPlayersUpdateVisibilityArgs = {
  ids: Array<Scalars['ID']>;
  visible?: Maybe<Scalars['Boolean']>;
};


export type MutationPlayerUpdateArgs = {
  id: Scalars['String'];
  input: PlayerUpdateInput;
};


export type MutationPromotionCreateArgs = {
  input: PromotionCreateInput;
};


export type MutationPromotionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPromotionsDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationPromotionUpdateArgs = {
  id: Scalars['ID'];
  input: PromotionUpdateInput;
};


export type MutationRefreshArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationSellArgs = {
  input: SellInput;
};


export type MutationShortArgs = {
  input: ShortInput;
};


export type MutationUserActivateAccountArgs = {
  token: Scalars['String'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  id: Scalars['String'];
};


export type MutationUserRequestPasswordResetArgs = {
  input: UserRequestPasswordResetInput;
};


export type MutationUserResetPasswordArgs = {
  input: UserPasswordResetInput;
};


export type MutationUsersDeleteArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUserSetTimeoutArgs = {
  endDate: Scalars['DateTime'];
};


export type MutationUserUpdateArgs = {
  id: Scalars['String'];
  input: UserUpdateInput;
};


export type MutationUserWatchlistAddManagerArgs = {
  managerId: Scalars['String'];
};


export type MutationUserWatchlistAddPlayerArgs = {
  playerId: Scalars['String'];
};


export type MutationUserWatchlistRemoveManagerArgs = {
  managerId: Scalars['String'];
};


export type MutationUserWatchlistRemovePlayerArgs = {
  playerId: Scalars['String'];
};

/** Status of buy/sell orders */
export enum OrderStatus {
  Complete = 'COMPLETE',
  Open = 'OPEN'
}

/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Are there more pages after this one? */
  hasNextPage: Scalars['Boolean'];
  /** Total number of items in the collection */
  total: Scalars['Float'];
};

/** Player model */
export type Player = {
  __typename?: 'Player';
  analystRating: AnalystRating;
  /** Player's appearance count */
  appearances?: Maybe<Scalars['Float']>;
  /** Player's associated articles */
  articles: Array<Article>;
  /** Player's current buy price */
  buyPrice: Scalars['Float'];
  /** Is the shorting feature enabled for this player */
  canShort: Scalars['Boolean'];
  /** Player's club */
  club: Club;
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Player's disabled stats */
  disabledStats?: Maybe<DisabledStats>;
  /** Player's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** Player's first name */
  firstName: Scalars['String'];
  /** Player's foot */
  foot?: Maybe<PlayerFoot>;
  /** Player's goals count */
  goals?: Maybe<Scalars['Float']>;
  /** Player's current height */
  height?: Maybe<Scalars['Float']>;
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Player's image (URL) */
  image?: Maybe<Scalars['String']>;
  isOnWatchlist: Scalars['Boolean'];
  /** Player's last name */
  lastName: Scalars['String'];
  marketStats: MarketStats;
  /** Player's minutes played */
  minutesPlayed?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** Player's nationality */
  nationality: Scalars['String'];
  /** Player's penalty goal count */
  penaltyGoals?: Maybe<Scalars['Float']>;
  /** Player's current position */
  position: PlayerPosition;
  /** Player's current price (not public facing) */
  price: Scalars['Float'];
  priceChange: EntityPriceChange;
  priceHistory: Candles;
  /** Player's red card count */
  redCards?: Maybe<Scalars['Float']>;
  /** Player's current sell price */
  sellPrice: Scalars['Float'];
  /** All open shares owned by the current user in this player */
  shares?: Maybe<ShareConnection>;
  /** Player's current shirt number */
  shirtNo?: Maybe<Scalars['Float']>;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User's watching players */
  usersWatching: Array<User>;
  /** Player's visibility */
  visible: Scalars['Boolean'];
  /** Player's current weight */
  weight?: Maybe<Scalars['Float']>;
  /** Player's yellow card count */
  yellowCards?: Maybe<Scalars['Float']>;
};


/** Player model */
export type PlayerPriceChangeArgs = {
  hours: Scalars['Float'];
};


/** Player model */
export type PlayerPriceHistoryArgs = {
  input: CandleGenerateInput;
};

/** Paginated list of players */
export type PlayerConnection = {
  __typename?: 'PlayerConnection';
  edges?: Maybe<Array<PlayerEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new player */
export type PlayerCreateInput = {
  /** Player's club ID. */
  clubId: Scalars['String'];
  /** Player's disabled stats */
  disabledStats?: Maybe<DisabledStatsInput>;
  /** Player's date of birth */
  dob: Scalars['DateTime'];
  /** Player's first name. 1-100 characters. */
  firstName: Scalars['String'];
  /** Player's foot */
  foot: Scalars['String'];
  /** Player's current height */
  height: Scalars['Float'];
  /** Player's image */
  image: Scalars['String'];
  /** Player's last name. 1-100 characters. */
  lastName: Scalars['String'];
  /** Player's nationality */
  nationality: Scalars['String'];
  /** Player's position */
  position: Scalars['String'];
  /** Player's price */
  price: Scalars['Float'];
  /** Player's current shirt number */
  shirtNo: Scalars['Float'];
  /** Player's visibility */
  visible: Scalars['Boolean'];
  /** Player's current weight */
  weight: Scalars['Float'];
};

export type PlayerEdge = {
  __typename?: 'PlayerEdge';
  cursor: Scalars['String'];
  node: Player;
};

/** Player's foot */
export enum PlayerFoot {
  Both = 'BOTH',
  Left = 'LEFT',
  Right = 'RIGHT',
  Unknown = 'UNKNOWN'
}

/** Player's position */
export enum PlayerPosition {
  Attacker = 'ATTACKER',
  Defender = 'DEFENDER',
  Goalkeeper = 'GOALKEEPER',
  Midfielder = 'MIDFIELDER',
  Striker = 'STRIKER'
}

export type PlayerUpdateInput = {
  /** Player's club ID. */
  clubId?: Maybe<Scalars['String']>;
  /** Player's disabled stats */
  disabledStats?: Maybe<DisabledStatsInput>;
  /** Player's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** Player's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  /** Player's foot */
  foot?: Maybe<Scalars['String']>;
  /** Player's current height */
  height?: Maybe<Scalars['Float']>;
  /** Player's image */
  image?: Maybe<Scalars['String']>;
  /** Player's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  /** Player's nationality */
  nationality?: Maybe<Scalars['String']>;
  /** Player's position */
  position?: Maybe<Scalars['String']>;
  /** Player's price */
  price?: Maybe<Scalars['Float']>;
  /** Player's current shirt number */
  shirtNo?: Maybe<Scalars['Float']>;
  /** Player's visibility */
  visible?: Maybe<Scalars['Boolean']>;
  /** Player's current weight */
  weight?: Maybe<Scalars['Float']>;
};

/** Address format */
export type PostalAddress = {
  __typename?: 'PostalAddress';
  /** Address Town or City */
  city: Scalars['String'];
  /** Address country */
  country: Scalars['String'];
  /** Address county */
  county: Scalars['String'];
  /** Address Line 1 */
  line1: Scalars['String'];
  /** Address Line 2 (optional) */
  line2?: Maybe<Scalars['String']>;
  /** Address postal code */
  postal?: Maybe<Scalars['String']>;
};

/** Addresses for postcode */
export type PostalAddresses = {
  __typename?: 'PostalAddresses';
  /** Known addresses associated with postcode */
  addresses: Array<PostalAddress>;
};

/** Full address input */
export type PostalAddressInput = {
  /** Address Town or City */
  city: Scalars['String'];
  /** Address country */
  country: Scalars['String'];
  /** Address county */
  county: Scalars['String'];
  /** Address Line 1 */
  line1: Scalars['String'];
  /** Address Line 2 (optional) */
  line2?: Maybe<Scalars['String']>;
  /** Address Postal Code */
  postal: Scalars['String'];
};

/** Promotion model */
export type Promotion = {
  __typename?: 'Promotion';
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Promotion's image */
  image: Scalars['String'];
  /** Promotion's link */
  link: Scalars['String'];
  /** Promotion's title */
  subtext: Scalars['String'];
  /** Promotion's title */
  title: Scalars['String'];
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** Promotion's visibility */
  visible: Scalars['Boolean'];
};

/** Paginated list of promotions */
export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  edges?: Maybe<Array<PromotionEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new promotion */
export type PromotionCreateInput = {
  /** Promotion's image URL. 1-128 characters. */
  image: Scalars['String'];
  /** Promotion's URL/Link. 1-128 characters. */
  link: Scalars['String'];
  /** Promotion's subtext. 1-100 characters. */
  subtext: Scalars['String'];
  /** Promotions's title. 1-100 characters. */
  title: Scalars['String'];
  /** Promotion's visibility */
  visible: Scalars['Boolean'];
};

export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  cursor: Scalars['String'];
  node: Promotion;
};

export type PromotionUpdateInput = {
  /** Promotion's image URL. 1-128 characters. */
  image?: Maybe<Scalars['String']>;
  /** Promotion's URL/Link. 1-128 characters. */
  link?: Maybe<Scalars['String']>;
  /** Promotion's subtext. 1-100 characters. */
  subtext?: Maybe<Scalars['String']>;
  /** Promotions's title. 1-100 characters. */
  title?: Maybe<Scalars['String']>;
  /** Promotion's visibility */
  visible?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get a single article */
  article?: Maybe<Article>;
  /** Get mutliple articles */
  articleMany: ArticleConnection;
  /** Get a paginated list of article objects */
  articles: ArticleConnection;
  /** Get all open buy orders */
  buyQueue: Array<Buy>;
  /** Get a single club */
  club?: Maybe<Club>;
  /** Get mutliple clubs */
  clubMany: ClubConnection;
  /** Get a paginated list of club objects */
  clubs: ClubConnection;
  /** Generate a fully formatted deposit URL */
  depositURL?: Maybe<Scalars['String']>;
  /** Get a single manager */
  manager?: Maybe<Manager>;
  /** Get mutliple managers */
  managerMany: ManagerConnection;
  /** Get a paginated list of manager objects */
  managers: ManagerConnection;
  /** Get the currently logged in user */
  me: User;
  /** Get a single player */
  player?: Maybe<Player>;
  /** Get mutliple players */
  playerMany: PlayerConnection;
  /** Get a paginated list of player objects */
  players: PlayerConnection;
  /** Find an address via a postal code */
  postalAddresses?: Maybe<PostalAddresses>;
  /** Get a single promotion */
  promotion?: Maybe<Promotion>;
  /** Get mutliple promotions */
  promotionMany: PromotionConnection;
  /** Get a paginated list of promotion objects */
  promotions: PromotionConnection;
  /** Get all open sell orders */
  sellQueue: Array<Sell>;
  /** Get a single share */
  share?: Maybe<Share>;
  /** Admin query to get all shares */
  shares: ShareConnection;
  /** Get mutliple shares */
  sharesMany: ShareConnection;
  /** Get the list of top performing managers within 24 hours */
  topMoversManager: Array<Manager>;
  /** Get the list of top performing players within 24 hours */
  topMoversPlayer: Array<Player>;
  /** Get a single user */
  user?: Maybe<User>;
  /** Get mutliple users */
  userMany: UserConnection;
  /** Get a paginated list of user objects */
  users: UserConnection;
  /** Generate a fully formatted withdrawal URL */
  withdrawURL?: Maybe<Scalars['String']>;
};


export type QueryArticleArgs = {
  id: Scalars['String'];
};


export type QueryArticleManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryArticlesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QueryClubArgs = {
  id: Scalars['String'];
};


export type QueryClubManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryClubsArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QueryManagerArgs = {
  id: Scalars['String'];
};


export type QueryManagerManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryManagersArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QueryPlayerArgs = {
  id: Scalars['String'];
};


export type QueryPlayerManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPlayersArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QueryPostalAddressesArgs = {
  postal: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['String'];
};


export type QueryPromotionManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPromotionsArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QueryShareArgs = {
  id: Scalars['String'];
};


export type QuerySharesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type QuerySharesManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUsersArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};

/** Sell order */
export type Sell = {
  __typename?: 'Sell';
  /** Order open date */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Sell at price */
  price: Scalars['Float'];
  /** Shares left to be sold */
  remainingShares: Scalars['Int'];
  /** Order status */
  status: OrderStatus;
  /** Automatically sells off shares if price falls to stop loss value */
  stopLoss?: Maybe<Scalars['Float']>;
  /** Automatically sells off shares if price rises to take profit value */
  takeProfit?: Maybe<Scalars['Float']>;
  /** Total shares at open */
  totalShares: Scalars['Int'];
  /** ID of associated trade */
  tradeID: Scalars['String'];
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
};

export type SellInput = {
  /** ID of the player or manager */
  entityID: Scalars['String'];
  /** The type of entity to buy or sell shares in */
  entityType: BuySellEntityType;
  /** Sell limit price (if not given, the shares will be instant bought at the current price) */
  price?: Maybe<Scalars['Float']>;
  /** ID of existing share. If not given, will short the market */
  shareID?: Maybe<Scalars['String']>;
  /** Number of shares to buy */
  shares: Scalars['Float'];
};

export type SellResponse = {
  __typename?: 'SellResponse';
  /** Number of shares bought by the market maker */
  marketMaker?: Maybe<Scalars['Float']>;
  /** Number of shares matched with sell queue */
  matched?: Maybe<Scalars['Float']>;
  /** Was the purchase successfull? */
  success: Scalars['Boolean'];
  /** Instant or order */
  type: Scalars['String'];
};

/** A set of shares at a given price point in an entity */
export type Share = {
  __typename?: 'Share';
  /** Number of shares in this set */
  amount: Scalars['Float'];
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Manager associated with shares (if applicable) */
  manager?: Maybe<Manager>;
  /** Are the shares still owned by the user */
  open: Scalars['Boolean'];
  /** Player associated with shares (if applicable) */
  player?: Maybe<Player>;
  /** Value of the set of shares */
  price: Scalars['Float'];
  /** Automatically sells off shares if price falls to stop loss value */
  stopLoss?: Maybe<Scalars['Float']>;
  /** Automatically sells off shares if price rises to take profit value */
  takeProfit?: Maybe<Scalars['Float']>;
  /** Trade that opened these shares */
  trade: Trade;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User who ones the shares */
  user: User;
};

/** Paginated list of shares */
export type ShareConnection = {
  __typename?: 'ShareConnection';
  edges?: Maybe<Array<ShareEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

export type ShareEdge = {
  __typename?: 'ShareEdge';
  cursor: Scalars['String'];
  node: Share;
};

export type ShortInput = {
  /** ID of the player or manager */
  entityID: Scalars['String'];
  /** The type of entity to short */
  entityType: BuySellEntityType;
  /** Number of shares to short */
  shares: Scalars['Float'];
  /** Automatically sell off shares if price falls to stop loss */
  stopLoss?: Maybe<Scalars['Float']>;
  /** Automatically sell off shares if price hits take profit */
  takeProfit?: Maybe<Scalars['Float']>;
};

export type ShortResponse = {
  __typename?: 'ShortResponse';
  /** Number of shares bought by the market maker */
  marketMaker?: Maybe<Scalars['Float']>;
  /** Number of shares matched with sell queue */
  matched?: Maybe<Scalars['Float']>;
  /** Was the purchase successfull? */
  success: Scalars['Boolean'];
  /** Instant or order */
  type: Scalars['String'];
};

/** User's terms of service */
export type TermsOfService = {
  __typename?: 'TermsOfService';
  /** IP of the user when the terms of service was agreed */
  agent: Scalars['String'];
  /** Date of when the user agreed to terms of service */
  date: Scalars['String'];
  /** IP of the user when the terms of service was agreed */
  ip: Scalars['String'];
};

export type Tokens = {
  __typename?: 'Tokens';
  /** An access token allowing the user to access protected resources. Only valid for 5 minutes. */
  accessToken: Scalars['String'];
  /** Date access token expires */
  accessTokenExpiration: Scalars['DateTime'];
  /** A refresh token allowing the user to request a new access token. Valid for 90 days. */
  refreshToken: Scalars['String'];
  /** The role of the user. */
  role: Scalars['String'];
};

/** A receipt of bought or sold shares */
export type Trade = {
  __typename?: 'Trade';
  /** (Sell trades) Commission taken from sale */
  commission?: Maybe<Scalars['Float']>;
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Manager associated with shares (if applicable) */
  manager?: Maybe<Manager>;
  /** Any messages related to the trade */
  message?: Maybe<Scalars['String']>;
  /** Method used to buy/sell in trade */
  method: TradeMethod;
  /** (Sell trades) Date shares were originall bought */
  openDate?: Maybe<Scalars['DateTime']>;
  /** (Sell trades) Price shares were originally bought at */
  openPrice?: Maybe<Scalars['Float']>;
  /** Player associated with shares (if applicable) */
  player?: Maybe<Player>;
  /** Price these shares were bought/sold at */
  price: Scalars['Float'];
  /** Associated share (if applicable) */
  share?: Maybe<Share>;
  /** Number of shares bought/sold in this transaction */
  shares: Scalars['Float'];
  /** Were the shares shorted? */
  short: Scalars['Boolean'];
  /** Trade's current state */
  status: TradeStatus;
  /** Were the shares bought or sold */
  type: TradeType;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User who bought the shares */
  user: User;
};

/** Paginated list of trades */
export type TradeConnection = {
  __typename?: 'TradeConnection';
  edges?: Maybe<Array<TradeEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

export type TradeEdge = {
  __typename?: 'TradeEdge';
  cursor: Scalars['String'];
  node: Trade;
};

/** Method of trade */
export enum TradeMethod {
  MarketMaker = 'MARKET_MAKER',
  Match = 'MATCH',
  Order = 'ORDER'
}

/** Possible states a trade may be in */
export enum TradeStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Open = 'OPEN'
}

/** Buy or sell trade */
export enum TradeType {
  Buy = 'BUY',
  Sell = 'SELL'
}

/** A record of a deposit or withdrawal */
export type Transaction = {
  __typename?: 'Transaction';
  /** Amount of money */
  amount: Scalars['Float'];
  /** Name of card company, if used */
  cardCompany?: Maybe<TransactionCardCompany>;
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** Currency (default GBP) */
  currency: Scalars['String'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** Payment method used */
  method: TransactionMethod;
  /** Status of the transaction */
  status: TransactionStatus;
  /** Nuvei transaction ID */
  transactionID: Scalars['String'];
  /** Type of transaction */
  type: TransactionType;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** Transaction's user */
  user: User;
};

/** Transaction card company options */
export enum TransactionCardCompany {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Lasercard = 'LASERCARD',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Other = 'OTHER',
  Solo = 'SOLO',
  Switch = 'SWITCH',
  Visa = 'VISA'
}

/** Paginated list of transactions */
export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges?: Maybe<Array<TransactionEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['String'];
  node: Transaction;
};

/** Transaction method options */
export enum TransactionMethod {
  ApplePay = 'APPLE_PAY',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  Other = 'OTHER',
  Paypal = 'PAYPAL'
}

/** Transaction status options */
export enum TransactionStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

/** Transaction type options */
export enum TransactionType {
  Deposit = 'DEPOSIT',
  Docup = 'DOCUP',
  Kyc = 'KYC',
  Withdrawal = 'WITHDRAWAL'
}

/** User (customer and staff) model */
export type User = {
  __typename?: 'User';
  /** Is the account activated */
  active: Scalars['Boolean'];
  /** User's address */
  address?: Maybe<PostalAddress>;
  /** Cash balance in the user's account */
  balance: Scalars['Float'];
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** User's date of birth */
  dob: Scalars['DateTime'];
  /** User's email address - unique */
  email: Scalars['String'];
  /** User's first name */
  firstName: Scalars['String'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** User's last name */
  lastName: Scalars['String'];
  /** Is the account locked */
  locked: Scalars['Boolean'];
  /** User's marketting preferences */
  marketing: MarketingPreferences;
  name: Scalars['String'];
  /** User's email. Must be in valid email format. */
  phone: Scalars['String'];
  /** User's role, specifying their access level */
  role: UserRole;
  /** All open shares owned by a user */
  shares: ShareConnection;
  /** User's account timeout */
  timeout?: Maybe<Scalars['DateTime']>;
  /** User's terms of service agreement */
  tos: TermsOfService;
  /** Cash balance + shares total */
  totalBalance: Scalars['Float'];
  /** All trades related to a user */
  trades: TradeConnection;
  /** All open shares owned by a user */
  transactions: TransactionConnection;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User's watched managers */
  watchedManagers: Array<Manager>;
  /** User's watched players */
  watchedPlayers: Array<Player>;
};


/** User (customer and staff) model */
export type UserSharesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


/** User (customer and staff) model */
export type UserTradesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


/** User (customer and staff) model */
export type UserTransactionsArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};

/** Paginated list of users */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<UserEdge>>;
  /** Pagination details */
  pageInfo: PageInfo;
};

/** Create a new user */
export type UserCreateInput = {
  /** User's address */
  address: PostalAddressInput;
  /** User's date of birth */
  dob: Scalars['DateTime'];
  /** User's email. Must be in valid email format. */
  email: Scalars['String'];
  /** User's first name. 1-100 characters. */
  firstName: Scalars['String'];
  /** User's last name. 1-100 characters. */
  lastName: Scalars['String'];
  /** User's marketing preferences. */
  marketing?: Maybe<MarketingPreferencesInput>;
  /** User's password. Min 8 characters */
  password: Scalars['String'];
  /** User's email. Must be in valid email format. */
  phone: Scalars['String'];
  /** User's terms of service agreement. */
  tos: Scalars['Boolean'];
};

export type UserCreateResponse = {
  __typename?: 'UserCreateResponse';
  /** Is the account activated */
  active: Scalars['Boolean'];
  /** User's address */
  address?: Maybe<PostalAddress>;
  /** Cash balance in the user's account */
  balance: Scalars['Float'];
  /** Date the object was created */
  created: Scalars['DateTime'];
  /** User's date of birth */
  dob: Scalars['DateTime'];
  /** User's email address - unique */
  email: Scalars['String'];
  /** User's first name */
  firstName: Scalars['String'];
  /** Globally unique identifier */
  id: Scalars['ID'];
  /** User's last name */
  lastName: Scalars['String'];
  /** Is the account locked */
  locked: Scalars['Boolean'];
  /** User's marketting preferences */
  marketing: MarketingPreferences;
  name: Scalars['String'];
  /** User's email. Must be in valid email format. */
  phone: Scalars['String'];
  /** User's role, specifying their access level */
  role: UserRole;
  /** All open shares owned by a user */
  shares: ShareConnection;
  /** User's account timeout */
  timeout?: Maybe<Scalars['DateTime']>;
  /** Access and refresh tokens for the newly created user to allow them to login in straight away */
  tokens: Tokens;
  /** User's terms of service agreement */
  tos: TermsOfService;
  /** Cash balance + shares total */
  totalBalance: Scalars['Float'];
  /** All trades related to a user */
  trades: TradeConnection;
  /** All open shares owned by a user */
  transactions: TransactionConnection;
  /** Date the object was last updated */
  updated: Scalars['DateTime'];
  /** User's watched managers */
  watchedManagers: Array<Manager>;
  /** User's watched players */
  watchedPlayers: Array<Player>;
};


export type UserCreateResponseSharesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type UserCreateResponseTradesArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};


export type UserCreateResponseTransactionsArgs = {
  filter?: Maybe<ConnectionFilter>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  sort?: Maybe<ConnectionSort>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

/** Reset a password for user with a token */
export type UserPasswordResetInput = {
  /** User's new password. Min 8 characters */
  password: Scalars['String'];
  /** User's reset password token */
  token: Scalars['String'];
};

/** Request a password reset for a user */
export type UserRequestPasswordResetInput = {
  /** User's email. Must be in valid email format. */
  email: Scalars['String'];
};

/** Permissions the user has */
export enum UserRole {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Staff = 'STAFF'
}

export type UserUpdateInput = {
  /** User's address */
  address?: Maybe<PostalAddressInput>;
  /** User's current password - required to update sensitive fields (email, password) */
  currentPassword?: Maybe<Scalars['String']>;
  /** User's date of birth */
  dob?: Maybe<Scalars['DateTime']>;
  /** User's email. Must be in valid email format. */
  email?: Maybe<Scalars['String']>;
  /** User's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  /** User's marketing preferences. */
  marketing?: Maybe<MarketingPreferencesInput>;
  /** User's password. Min 8 characters */
  password?: Maybe<Scalars['String']>;
  /** User's email. Must be in valid email format. */
  phone?: Maybe<Scalars['String']>;
  /** User's terms of service agreement. */
  tos?: Maybe<Scalars['Boolean']>;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email'>
  ) }
);

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = (
  { __typename?: 'Mutation' }
  & { refresh?: Maybe<(
    { __typename?: 'Tokens' }
    & Pick<Tokens, 'accessToken'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'Tokens' }
    & Pick<Tokens, 'accessToken'>
  )> }
);

export type GetBuyQueueQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBuyQueueQuery = (
  { __typename?: 'Query' }
  & { buyQueue: Array<(
    { __typename?: 'Buy' }
    & Pick<Buy, 'id' | 'price' | 'totalShares' | 'remainingShares' | 'status' | 'tradeID' | 'created'>
  )> }
);

export type GetSellQueueQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSellQueueQuery = (
  { __typename?: 'Query' }
  & { sellQueue: Array<(
    { __typename?: 'Sell' }
    & Pick<Sell, 'id' | 'price' | 'totalShares' | 'remainingShares' | 'status' | 'tradeID' | 'created'>
  )> }
);

export type GetSharesQueryVariables = Exact<{
  limit: Scalars['Float'];
  page: Scalars['Float'];
}>;


export type GetSharesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { shares: (
      { __typename?: 'ShareConnection' }
      & { edges?: Maybe<Array<(
        { __typename?: 'ShareEdge' }
        & { node: (
          { __typename?: 'Share' }
          & Pick<Share, 'id' | 'amount' | 'price' | 'stopLoss' | 'takeProfit' | 'created'>
          & { manager?: Maybe<(
            { __typename?: 'Manager' }
            & Pick<Manager, 'id' | 'firstName' | 'lastName' | 'buyPrice'>
          )>, player?: Maybe<(
            { __typename?: 'Player' }
            & Pick<Player, 'id' | 'firstName' | 'lastName' | 'buyPrice'>
          )> }
        ) }
      )>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'total'>
      ) }
    ) }
  ) }
);

export type BuyMutationVariables = Exact<{
  input: BuyInput;
}>;


export type BuyMutation = (
  { __typename?: 'Mutation' }
  & { buy: (
    { __typename?: 'BuyResponse' }
    & Pick<BuyResponse, 'type' | 'success' | 'marketMaker' | 'matched'>
  ) }
);

export type SellMutationVariables = Exact<{
  input: SellInput;
}>;


export type SellMutation = (
  { __typename?: 'Mutation' }
  & { sell: (
    { __typename?: 'SellResponse' }
    & Pick<SellResponse, 'type' | 'success'>
  ) }
);

export type GetTradesQueryVariables = Exact<{
  limit: Scalars['Float'];
  page: Scalars['Float'];
}>;


export type GetTradesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { trades: (
      { __typename?: 'TradeConnection' }
      & { edges?: Maybe<Array<(
        { __typename?: 'TradeEdge' }
        & { node: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'id' | 'price' | 'shares' | 'status' | 'type' | 'method' | 'updated' | 'created'>
          & { player?: Maybe<(
            { __typename?: 'Player' }
            & Pick<Player, 'id' | 'firstName' | 'lastName' | 'buyPrice'>
          )>, manager?: Maybe<(
            { __typename?: 'Manager' }
            & Pick<Manager, 'id' | 'firstName' | 'lastName' | 'buyPrice'>
          )> }
        ) }
      )>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'total'>
      ) }
    ) }
  ) }
);

export type GetBalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBalancesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'balance' | 'totalBalance'>
  ) }
);


export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RefreshDocument = gql`
    mutation refresh {
  refresh {
    accessToken
  }
}
    `;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, baseOptions);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetBuyQueueDocument = gql`
    query getBuyQueue {
  buyQueue {
    id
    price
    totalShares
    remainingShares
    status
    tradeID
    created
  }
}
    `;

/**
 * __useGetBuyQueueQuery__
 *
 * To run a query within a React component, call `useGetBuyQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBuyQueueQuery(baseOptions?: Apollo.QueryHookOptions<GetBuyQueueQuery, GetBuyQueueQueryVariables>) {
        return Apollo.useQuery<GetBuyQueueQuery, GetBuyQueueQueryVariables>(GetBuyQueueDocument, baseOptions);
      }
export function useGetBuyQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuyQueueQuery, GetBuyQueueQueryVariables>) {
          return Apollo.useLazyQuery<GetBuyQueueQuery, GetBuyQueueQueryVariables>(GetBuyQueueDocument, baseOptions);
        }
export type GetBuyQueueQueryHookResult = ReturnType<typeof useGetBuyQueueQuery>;
export type GetBuyQueueLazyQueryHookResult = ReturnType<typeof useGetBuyQueueLazyQuery>;
export type GetBuyQueueQueryResult = Apollo.QueryResult<GetBuyQueueQuery, GetBuyQueueQueryVariables>;
export const GetSellQueueDocument = gql`
    query getSellQueue {
  sellQueue {
    id
    price
    totalShares
    remainingShares
    status
    tradeID
    created
  }
}
    `;

/**
 * __useGetSellQueueQuery__
 *
 * To run a query within a React component, call `useGetSellQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellQueueQuery(baseOptions?: Apollo.QueryHookOptions<GetSellQueueQuery, GetSellQueueQueryVariables>) {
        return Apollo.useQuery<GetSellQueueQuery, GetSellQueueQueryVariables>(GetSellQueueDocument, baseOptions);
      }
export function useGetSellQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellQueueQuery, GetSellQueueQueryVariables>) {
          return Apollo.useLazyQuery<GetSellQueueQuery, GetSellQueueQueryVariables>(GetSellQueueDocument, baseOptions);
        }
export type GetSellQueueQueryHookResult = ReturnType<typeof useGetSellQueueQuery>;
export type GetSellQueueLazyQueryHookResult = ReturnType<typeof useGetSellQueueLazyQuery>;
export type GetSellQueueQueryResult = Apollo.QueryResult<GetSellQueueQuery, GetSellQueueQueryVariables>;
export const GetSharesDocument = gql`
    query getShares($limit: Float!, $page: Float!) {
  me {
    shares(limit: $limit, page: $page) {
      edges {
        node {
          id
          amount
          price
          stopLoss
          takeProfit
          manager {
            id
            firstName
            lastName
            buyPrice
          }
          player {
            id
            firstName
            lastName
            buyPrice
          }
          created
        }
      }
      pageInfo {
        total
      }
    }
  }
}
    `;

/**
 * __useGetSharesQuery__
 *
 * To run a query within a React component, call `useGetSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetSharesQuery(baseOptions: Apollo.QueryHookOptions<GetSharesQuery, GetSharesQueryVariables>) {
        return Apollo.useQuery<GetSharesQuery, GetSharesQueryVariables>(GetSharesDocument, baseOptions);
      }
export function useGetSharesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharesQuery, GetSharesQueryVariables>) {
          return Apollo.useLazyQuery<GetSharesQuery, GetSharesQueryVariables>(GetSharesDocument, baseOptions);
        }
export type GetSharesQueryHookResult = ReturnType<typeof useGetSharesQuery>;
export type GetSharesLazyQueryHookResult = ReturnType<typeof useGetSharesLazyQuery>;
export type GetSharesQueryResult = Apollo.QueryResult<GetSharesQuery, GetSharesQueryVariables>;
export const BuyDocument = gql`
    mutation buy($input: BuyInput!) {
  buy(input: $input) {
    type
    success
    marketMaker
    matched
  }
}
    `;
export type BuyMutationFn = Apollo.MutationFunction<BuyMutation, BuyMutationVariables>;

/**
 * __useBuyMutation__
 *
 * To run a mutation, you first call `useBuyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyMutation, { data, loading, error }] = useBuyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuyMutation(baseOptions?: Apollo.MutationHookOptions<BuyMutation, BuyMutationVariables>) {
        return Apollo.useMutation<BuyMutation, BuyMutationVariables>(BuyDocument, baseOptions);
      }
export type BuyMutationHookResult = ReturnType<typeof useBuyMutation>;
export type BuyMutationResult = Apollo.MutationResult<BuyMutation>;
export type BuyMutationOptions = Apollo.BaseMutationOptions<BuyMutation, BuyMutationVariables>;
export const SellDocument = gql`
    mutation sell($input: SellInput!) {
  sell(input: $input) {
    type
    success
  }
}
    `;
export type SellMutationFn = Apollo.MutationFunction<SellMutation, SellMutationVariables>;

/**
 * __useSellMutation__
 *
 * To run a mutation, you first call `useSellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellMutation, { data, loading, error }] = useSellMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellMutation(baseOptions?: Apollo.MutationHookOptions<SellMutation, SellMutationVariables>) {
        return Apollo.useMutation<SellMutation, SellMutationVariables>(SellDocument, baseOptions);
      }
export type SellMutationHookResult = ReturnType<typeof useSellMutation>;
export type SellMutationResult = Apollo.MutationResult<SellMutation>;
export type SellMutationOptions = Apollo.BaseMutationOptions<SellMutation, SellMutationVariables>;
export const GetTradesDocument = gql`
    query getTrades($limit: Float!, $page: Float!) {
  me {
    trades(limit: $limit, page: $page) {
      edges {
        node {
          id
          price
          shares
          status
          type
          method
          player {
            id
            firstName
            lastName
            buyPrice
          }
          manager {
            id
            firstName
            lastName
            buyPrice
          }
          updated
          created
        }
      }
      pageInfo {
        total
      }
    }
  }
}
    `;

/**
 * __useGetTradesQuery__
 *
 * To run a query within a React component, call `useGetTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTradesQuery(baseOptions: Apollo.QueryHookOptions<GetTradesQuery, GetTradesQueryVariables>) {
        return Apollo.useQuery<GetTradesQuery, GetTradesQueryVariables>(GetTradesDocument, baseOptions);
      }
export function useGetTradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTradesQuery, GetTradesQueryVariables>) {
          return Apollo.useLazyQuery<GetTradesQuery, GetTradesQueryVariables>(GetTradesDocument, baseOptions);
        }
export type GetTradesQueryHookResult = ReturnType<typeof useGetTradesQuery>;
export type GetTradesLazyQueryHookResult = ReturnType<typeof useGetTradesLazyQuery>;
export type GetTradesQueryResult = Apollo.QueryResult<GetTradesQuery, GetTradesQueryVariables>;
export const GetBalancesDocument = gql`
    query getBalances {
  me {
    balance
    totalBalance
  }
}
    `;

/**
 * __useGetBalancesQuery__
 *
 * To run a query within a React component, call `useGetBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBalancesQuery(baseOptions?: Apollo.QueryHookOptions<GetBalancesQuery, GetBalancesQueryVariables>) {
        return Apollo.useQuery<GetBalancesQuery, GetBalancesQueryVariables>(GetBalancesDocument, baseOptions);
      }
export function useGetBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBalancesQuery, GetBalancesQueryVariables>) {
          return Apollo.useLazyQuery<GetBalancesQuery, GetBalancesQueryVariables>(GetBalancesDocument, baseOptions);
        }
export type GetBalancesQueryHookResult = ReturnType<typeof useGetBalancesQuery>;
export type GetBalancesLazyQueryHookResult = ReturnType<typeof useGetBalancesLazyQuery>;
export type GetBalancesQueryResult = Apollo.QueryResult<GetBalancesQuery, GetBalancesQueryVariables>;