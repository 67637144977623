import {
    Chip,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import React from "react";
import {
    TradeMethod,
    TradeStatus,
    TradeType,
} from "../../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
    buy: {
        backgroundColor: "#74FAD3",
        color: "#000",
    },
    sell: {
        backgroundColor: "#FF1061",
        color: "#fff",
    },
    id: {
        position: "absolute",
        padding: theme.spacing(0.5),
        borderRadius: 5,
        backgroundColor: "white",
        border: "1px solid black",
        fontFamily: `"monospace", "monospace"`,
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

interface ITradeProps {
    id: string;
    shares: number;
    price: number;
    entityID: string;
    entityName: string;
    entityPrice: number;
    status: TradeStatus;
    type: TradeType;
    method: TradeMethod;
    // trade: string;
    created: Date;
}

const Trade = ({
    id,
    shares,
    price,
    entityID,
    entityName,
    entityPrice,
    status,
    type,
    method,
    // trade,
    created,
}: ITradeProps) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.long}>{id}</TableCell>
            <TableCell className={classes.short} align="right">
                {shares}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {price.toFixed(2)}
            </TableCell>
            <TableCell className={classes.long}>
                <Tooltip title={entityID} arrow interactive>
                    <span>
                        <span className={classes.bold}>
                            £{entityPrice.toFixed(2)}
                        </span>{" "}
                        - {entityName}
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell className={classes.short}>
                <Chip
                    label={type}
                    className={
                        type === TradeType.Buy ? classes.buy : classes.sell
                    }
                />
            </TableCell>
            <TableCell className={classes.short}>{method}</TableCell>
            <TableCell className={classes.short}>{status}</TableCell>
            {/* <TableCell>{trade}</TableCell> */}
            <TableCell className={classes.long}>
                {created.toUTCString()}
            </TableCell>
        </TableRow>
    );
};

export default Trade;
