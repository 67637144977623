import {
    ApolloClient,
    ApolloLink,
    fromPromise,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useAccessToken } from "./useAccessToken";

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: "include",
});

const refreshToken = async (setAccessToken: (accessToken: string) => void) => {
    const res = await fetch(process.env.REACT_APP_API_URL ?? "", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body:
            '{"query":"mutation refresh {\\n  refresh {\\n    accessToken\\n  }\\n}","operationName":"refresh"}',
    });
    const { data } = (await res.json()) as {
        data?: {
            refresh?: {
                accessToken: string;
            };
        };
    };

    if (data?.refresh?.accessToken) {
        setAccessToken(data.refresh.accessToken);
        return data.refresh.accessToken;
    }
    return null;
};

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
};

/* eslint-disable no-loop-func */
const createErrorLink = (
    setAccessToken: (accessToken: string) => void,
    removeAccessToken: () => void
) =>
    onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            console.log(graphQLErrors, operation.operationName);
            for (let err of graphQLErrors) {
                switch (err.extensions?.code) {
                    case "UNAUTHENTICATED":
                        let forward$;

                        if (!isRefreshing) {
                            isRefreshing = true;
                            forward$ = fromPromise(
                                refreshToken(setAccessToken)
                                    .then((accessToken: string | null) => {
                                        // Store the new tokens for your auth link
                                        resolvePendingRequests();
                                        return accessToken;
                                    })
                                    .catch((error) => {
                                        pendingRequests = [];

                                        // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                                        removeAccessToken();
                                        return;
                                    })
                                    .finally(() => {
                                        isRefreshing = false;
                                    })
                            ).filter((value) => Boolean(value));
                        } else {
                            // Will only emit once the Promise is resolved
                            forward$ = fromPromise(
                                new Promise((resolve) => {
                                    pendingRequests.push(() =>
                                        resolve(setAccessToken)
                                    );
                                })
                            );
                        }
                        forward$?.flatMap((accessToken) => {
                            const oldHeaders = operation.getContext().headers;
                            // modify the operation context with a new token
                            operation.setContext({
                                headers: {
                                    ...oldHeaders,
                                    authorization: `Bearer ${accessToken}`,
                                },
                            });

                            // retry the request, returning the new observable
                            return forward(operation);
                        });
                }
            }
        }
    });

const authLink = (accessToken: string) =>
    new ApolloLink((operation, forward) => {
        if (accessToken) {
            operation.setContext(() => {
                return {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                };
            });
        }

        return forward(operation);
    });

const cache = new InMemoryCache();

export const useAppApolloClient = () => {
    const [accessToken, setAccessToken, removeAccessToken] = useAccessToken();

    return new ApolloClient({
        link: ApolloLink.from([
            // createErrorLink(setAccessToken, removeAccessToken),
            authLink(accessToken ?? ""),
            httpLink,
        ]),
        credentials: "include",
        cache,
    });
};
