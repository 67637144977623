import React from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import Title from "../../../../common/components/Title";
import { useGetBalancesQuery } from "../../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    container: {
        marginTop: theme.spacing(4),
    },
    card: {
        // backgroundColor: theme.palette.secondary.light
    },
}));

const Wallet = () => {
    const classes = useStyles();
    const { data: balance, loading: balanceLoading } = useGetBalancesQuery();

    return (
        <Paper className={classes.paper}>
            <Title icon={AccountBalanceIcon}>Wallet</Title>

            <Grid container spacing={2} className={classes.container}>
                <Grid item>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Cash Balance"
                            subheader="Monetary amount"
                        />
                        <CardContent>
                            {balanceLoading ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                <Typography variant="h6">
                                    {balance?.me
                                        ? `£${balance.me.balance.toFixed(2)}`
                                        : ""}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardHeader
                            title="Total"
                            subheader="Shares total + Cash Balance"
                        />
                        <CardContent>
                            <Typography variant="h6">
                                {balanceLoading ? (
                                    <CircularProgress color="secondary" />
                                ) : (
                                    <Typography variant="h6">
                                        {balance?.me
                                            ? `£${balance.me.totalBalance.toFixed(
                                                  2
                                              )}`
                                            : ""}
                                    </Typography>
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Wallet;
