import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import { BuySellEntityType } from "../../../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > *": {
            margin: theme.spacing(1),
            width: "75%",
        },
    },
    buyButton: {
        marginRight: theme.spacing(1),
    },
    sltp: {
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
    },
    stopLoss: {
        display: "flex",
        flex: 1,
    },
}));

interface BuyFormProps {
    price: string | undefined | null;
    setPrice: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    stopLoss: string | undefined | null;
    setStopLoss: React.Dispatch<
        React.SetStateAction<string | undefined | null>
    >;
    takeProfit: string | undefined | null;
    setTakeProfit: React.Dispatch<
        React.SetStateAction<string | undefined | null>
    >;
    shares: string;
    setShares: React.Dispatch<React.SetStateAction<string>>;
    entityID: string;
    setEntityID: React.Dispatch<React.SetStateAction<string>>;
    entityType: BuySellEntityType;
    setEntityType: React.Dispatch<React.SetStateAction<BuySellEntityType>>;
    clearForm: () => void;
    onSubmit: () => Promise<void>;
}

const BuyForm = ({
    price,
    setPrice,
    stopLoss,
    setStopLoss,
    takeProfit,
    setTakeProfit,
    shares,
    setShares,
    entityID,
    setEntityID,
    entityType,
    setEntityType,
    clearForm,
    onSubmit,
}: BuyFormProps) => {
    const classes = useStyles();

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <List className={classes.root}>
                <TextField
                    variant="filled"
                    label="Price (Optional)"
                    helperText="Leave empty to instant buy"
                    // type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <div className={classes.sltp}>
                    <TextField
                        variant="filled"
                        label="Stop Loss (Optional)"
                        helperText="Sell when price hits"
                        // type="number"
                        value={stopLoss}
                        onChange={(e) => setStopLoss(e.target.value)}
                        className={classes.stopLoss}
                    />
                    <TextField
                        variant="filled"
                        label="Take Profit (Optional)"
                        helperText="Sell when price hits"
                        // type="number"
                        value={takeProfit}
                        onChange={(e) => setTakeProfit(e.target.value)}
                        className={classes.stopLoss}
                    />
                </div>
                <TextField
                    variant="filled"
                    label="Shares"
                    helperText="Number of shares to buy"
                    // type="number"
                    required
                    value={shares}
                    onChange={(e) => setShares(e.target.value)}
                />
                <TextField
                    variant="filled"
                    label="Player/Manager ID"
                    helperText="IDs can be found in the admin panel player/manager list"
                    required
                    value={entityID}
                    onChange={(e) => setEntityID(e.target.value)}
                />
                <FormControl component="fieldset">
                    <FormLabel component="legend">Entity Type</FormLabel>
                    <RadioGroup
                        aria-label="entityType"
                        name="entityType1"
                        value={entityType}
                        onChange={(e) =>
                            setEntityType(e.target.value as BuySellEntityType)
                        }
                    >
                        <FormControlLabel
                            value={BuySellEntityType.Player}
                            control={<Radio />}
                            label="Player"
                        />
                        <FormControlLabel
                            value={BuySellEntityType.Manager}
                            control={<Radio />}
                            label="Manager"
                        />
                    </RadioGroup>
                </FormControl>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buyButton}
                        onClick={onSubmit}
                    >
                        Buy
                    </Button>
                    <Button variant="outlined" onClick={clearForm}>
                        Clear
                    </Button>
                </Box>
            </List>
        </form>
    );
};

export default BuyForm;
