import { useLoginMutation } from "../../generated/graphql";
import { useAccessToken } from "./useAccessToken";

export const useLogin = () => {
    const [getTokens] = useLoginMutation();
    const [, setAccessToken, removeAccessToken] = useAccessToken();

    const login = async (
        email: string,
        password: string
    ): Promise<boolean | string> => {
        removeAccessToken();
        try {
            const { data } = await getTokens({
                variables: {
                    email,
                    password,
                },
            });

            if (data?.login?.accessToken) {
                setAccessToken(data.login.accessToken);
                return true;
            }
            return false;
        } catch (err) {
            return err;
        }
    };

    return login;
};
