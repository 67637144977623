import { useMeQuery } from "../../generated/graphql"
import Layout from "../../pages/main/main.page"
import LoginPage from "../../pages/login/login.page"
import { useAccessToken } from "../hooks/useAccessToken"

export const AuthGate = () => {
    const { data: user } = useMeQuery()
    const [accessToken] = useAccessToken()

    if (user?.me && accessToken) {
        return <Layout user={user.me} />
    }

    return <LoginPage />
}
