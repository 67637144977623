import React from "react";
import {
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useGetBuyQueueQuery } from "../../../../generated/graphql";
import BuyLimit from "./BuyLimit";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
}));

interface Props {}

const BuyQueueTable = (props: Props) => {
    const classes = useStyles();

    const { data: buyLimits, loading, refetch } = useGetBuyQueueQuery({
        notifyOnNetworkStatusChange: true,
    });

    return (
        <TableContainer component={Paper}>
            <Button onClick={() => refetch()}>
                <RefreshIcon />
                Refresh
            </Button>
            <Table>
                <TableHead>
                    <TableCell className={classes.long}>ID</TableCell>
                    <TableCell className={classes.short} align="right">
                        Price
                    </TableCell>
                    <TableCell className={classes.short} align="right">
                        Total Shares
                    </TableCell>
                    <TableCell className={classes.short}>
                        Remaining Shares
                    </TableCell>
                    <TableCell className={classes.short}>Status</TableCell>
                    <TableCell className={classes.long}>Trade ID</TableCell>
                    <TableCell className={classes.long}>Opened</TableCell>
                </TableHead>
            </Table>
            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <TableBody>
                    {buyLimits?.buyQueue?.map((buyLimit) => (
                        <BuyLimit
                            {...buyLimit}
                            key={buyLimit.id}
                            created={new Date(buyLimit.created)}
                        />
                    ))}
                </TableBody>
            )}
        </TableContainer>
    );
};

export default BuyQueueTable;
