import { useApolloClient } from "@apollo/client";
import { useLogoutMutation } from "../../generated/graphql";
import { useAccessToken } from "./useAccessToken";

export const useLogout = () => {
    const [revokeToken] = useLogoutMutation();
    const [, , removeAccessToken] = useAccessToken();
    const apolloClient = useApolloClient();

    const logout = async () => {
        await revokeToken();
        await apolloClient.clearStore();
        removeAccessToken();
    };

    return logout;
};
