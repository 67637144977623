import React from "react";
import { makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    long: {
        // fontSize: "10px",
        width: 600,
        minWidth: 1,
    },
    short: {
        // fontSize: "10px",
        width: 100,
    },
    id: {
        position: "absolute",
        padding: theme.spacing(0.5),
        borderRadius: 5,
        backgroundColor: "white",
        border: "1px solid black",
        fontFamily: `"monospace", "monospace"`,
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

interface IShareProps {
    id: string;
    amount: number;
    price: number;
    entityID: string;
    entityName: string;
    entityPrice: number;
    stopLoss?: number | null;
    takeProfit?: number | null;
    // trade: string;
    created: Date;
}

const Share = ({
    id,
    amount,
    price,
    entityID,
    entityName,
    entityPrice,
    stopLoss,
    takeProfit,
    // trade,
    created,
}: IShareProps) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.long}>{id}</TableCell>
            <TableCell className={classes.short} align="right">
                {amount}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {price.toFixed(2)}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {stopLoss ? stopLoss.toFixed(2) : " - "}
            </TableCell>
            <TableCell className={classes.short} align="right">
                {takeProfit ? takeProfit.toFixed(2) : " - "}
            </TableCell>
            <TableCell className={classes.long}>
                <Tooltip title={entityID} arrow interactive>
                    <span>
                        <span className={classes.bold}>
                            £{entityPrice.toFixed(2)}
                        </span>{" "}
                        - {entityName}
                    </span>
                </Tooltip>
            </TableCell>
            {/* <TableCell>{trade}</TableCell> */}
            <TableCell className={classes.long}>
                {created.toUTCString()}
            </TableCell>
        </TableRow>
    );
};

export default Share;
