import React, { useState } from "react"
import { Box, Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core"
import StockIcon from "@material-ui/icons/ShowChart"
import Header from "../../common/components/Header"
import { User } from "../../generated/graphql"
import BuyQueueTable from "./components/buy/BuyQueueTable"
import SellQueueTable from "./components/sell/SellQueueTable"
import SharesTable from "./components/shares/SharesTable"
import SideBar from "./components/sidebar/SideBar"
import TabPanel from "./components/TapPanel"
import TradesTable from "./components/trades/TradesTable"
import Wallet from "./components/wallet/Wallet"
import Title from "../../common/components/Title"

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2)
    }
}))

interface ILayoutProps {
    user: {
        __typename?: "User" | undefined
    } & Pick<User, "id" | "name" | "email">
}

const Layout = ({ user }: ILayoutProps) => {
    const classes = useStyles()
    const [tabIndex, setTabIndex] = useState<number>(0)

    return (
        <Box>
            <Header name={user.name} />
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={7}>
                    <Paper className={classes.paper} elevation={3}>
                        <Title icon={StockIcon}>Records</Title>
                        <Tabs
                            value={tabIndex}
                            onChange={(e, value) => setTabIndex(value)}
                        >
                            <Tab label="My Shares" />
                            <Tab label="My Trades" />
                            <Tab label="Sell Queue" />
                            <Tab label="Buy Queue" />
                        </Tabs>
                        <TabPanel index={0} selectedIndex={tabIndex}>
                            <SharesTable />
                        </TabPanel>
                        <TabPanel index={1} selectedIndex={tabIndex}>
                            <TradesTable />
                        </TabPanel>
                        <TabPanel index={2} selectedIndex={tabIndex}>
                            <SellQueueTable />
                        </TabPanel>
                        <TabPanel index={3} selectedIndex={tabIndex}>
                            <BuyQueueTable />
                        </TabPanel>
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Wallet />
                    <SideBar />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Layout
